import getConfig from 'next/config';
import NextImage from 'next/legacy/image';

import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

const imageLoader = ({ src, width, quality = 80 }) => {
  const { publicRuntimeConfig } = getConfig();
  const shouldUseCfImages =
    publicRuntimeConfig.useCfImages?.toLowerCase() === 'true';

  if (src.startsWith('/')) {
    return src;
  }

  if (shouldUseCfImages) {
    const params = [`width=${width},quality=${quality}`];
    const origin = new URL(src).origin;
    const pathname = new URL(src).pathname;

    return `${origin}/cdn-cgi/image/${params}${pathname}`;
  } else {
    return `/api/imageproxy?url=${encodeURIComponent(src)}`;
  }
};

const Image = ({
  src,
  placeholderSrc = '/images/placeholder.jpeg',
  ...props
}) => {
  const [loading, setLoading] = useState(props.loading);
  const [url, setUrl] = useState(src ?? placeholderSrc);

  useEffect(() => {
    // Skip if image is already eager or has priority (disables lazy loading)
    if (props.loading === 'eager' || props.priority) {
      return;
    }

    if (!isMobileConnection()) {
      let clearDefer;
      // Set loading to eager if all resources of document are loaded, but defer it a bit
      const onLoad = () => {
        clearDefer = defer(() => setLoading('eager'));
      };

      window.addEventListener('load', onLoad);

      return () => {
        // Clean up the load event listener and an eventual defer
        window.removeEventListener('load', onLoad);

        if (clearDefer) {
          clearDefer();
        }
      };
    }
  }, [props.loading, props.priority]);

  if (isEmpty(url)) {
    return null;
  }

  if (src?.includes('.svg')) {
    return <img src={src} alt="" loading={loading} {...props} />;
  }

  return (
    <NextImage
      loader={imageLoader}
      loading={loading}
      src={url}
      onError={() => {
        setUrl(placeholderSrc);
      }}
      {...props}
    />
  );
};

const isMobileConnection = () => {
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;

  return (
    connection?.type === 'cellular' ||
    connection?.effectiveType === 'slow-2g' ||
    connection?.effectiveType === '2g' ||
    connection?.effectiveType === '3g' ||
    connection?.saveData === true
  );
};

const defer = callback => {
  // Check if we can use requestIdleCallback
  if (window.requestIdleCallback) {
    const handle = window.requestIdleCallback(callback);

    return () => window.cancelIdleCallback(handle);
  }

  // Just defer using setTimeout with some random delay otherwise
  const handle = setTimeout(callback, 2345 + Math.random() * 1000);

  return () => clearTimeout(handle);
};

export default Image;
